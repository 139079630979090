import React, { useState, useEffect, useCallback } from 'react';
import { useAdminAuth } from 'contexts/AuthContextAdmin';
import Widget from './components/Widget';
import banner from 'assets/img/profile/banner.png';
import Card from 'components/card';
import md5 from 'blueimp-md5';
import { Spinner } from '@chakra-ui/spinner';
import api from '../../../api.js';
import {
  FaCoins,
  FaGlobe,
  FaUsers,
  FaServer,
  FaCircle,
  FaBoxes,
} from 'react-icons/fa';
import InputField from 'components/fields/InputField';
import { Textarea } from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';
import { AiFillExclamationCircle } from 'react-icons/ai';
import SolidSubtleAlert from 'components/SolidSubtleAlert';

const Dashboard = () => {
  const auth = useAdminAuth();
  const user = auth.user;
  const storedDatetime = user.last_login;
  const storedDate = new Date(storedDatetime);
  const currentDate = new Date();
  const timeDifference = currentDate - storedDate;
  const minutesDifference = Math.round(Math.abs(timeDifference) / 60000);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});
  const [license_data, setLicenseData] = useState({});
  const [changelog, setChangeLog] = useState('');
  const hash = useCallback((email) => {
    const lowerCaseEmail = email.toLowerCase().trim();
    return md5(lowerCaseEmail);
  }, []);
  const get_pfp = useCallback(
    (email) => {
      return `https://www.gravatar.com/avatar/${hash(email)}`;
    },
    [hash]
  );
  function extractNumber(text) {
    if (typeof text !== 'string') {
      return 'Unlimited';
    }

    const matches = text.match(/\d+/g);

    if (matches === null || matches.length !== 1) {
      return 'Unlimited';
    }

    return matches[0];
  }
  useEffect(() => {
    api
      .get(`/admin/stats`)
      .then((response) => {
        if (response.data.status === 'success') {
          setStats(response.data);
          api
            .get(`/admin/license`)
            .then((response) => {
              if (response.data.status === 'success') {
                setLicenseData(response.data.license_data);
                api
                  .get(`/admin/changelog`)
                  .then((response) => {
                    if (response.data.status === 'success') {
                      setChangeLog(response.data.changelog);
                    }
                  })
                  .catch((error) => {
                    console.error('Error getting Servers: ', error);
                  });
              }
            })
            .catch((error) => {
              console.error('Error getting Servers: ', error);
            });
        }
      })
      .catch((error) => {
        console.error('Error getting Servers: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="h-full w-full rounded-md">
      {/* Reserved for future statistics */}
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          {extractNumber(license_data.productname) !== 'Unlimited' ? (
            <>
              {stats.users > (extractNumber(license_data.productname) - 100) ? (
                <div className='mb-3 mt-5'>
                <SolidSubtleAlert
                  title={'User Count Getting Close to License Limit'}
                  description="Upgrade your license in time to avoid any interruption to your service(s)."
                  icon={<AiFillExclamationCircle />}
                  iconColor="text-white"
                  closeBg="hover:bg-white/20 text-white"
                  bg="bg-red-500 dark:!bg-red-400"
                  solid="solid"
                /></div>
              ) : null}
            </>
          ) : null}
          <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-md xl:flex-row">
            {/* right section */}
            <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
              <Card extra={'items-center w-full h-full p-[16px] bg-cover'}>
                {/* Background and profile */}
                <div
                  className="relative mt-1 flex h-32 w-full justify-center rounded-md bg-cover"
                  style={{ backgroundImage: `url(${banner})` }}
                >
                  <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white dark:!border-navy-700">
                    <img
                      className="h-full w-full rounded-full"
                      src={get_pfp(auth.user.email)}
                      alt={
                        'Profile Picture of' +
                        auth.user.firstname +
                        ' ' +
                        auth.user.lastname
                      }
                    />
                  </div>
                </div>

                {/* Name and position */}
                <div className="mt-16 flex flex-col items-center">
                  <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    {auth.user.firstname + ' ' + auth.user.lastname}
                  </h4>
                  <h5 className="text-base font-normal text-gray-600">
                    Administrator
                  </h5>
                </div>

                {/* Post followers */}
                <div className="mb-3 mt-6 flex gap-4 md:!gap-14">
                  <div className="flex flex-col items-center justify-center">
                    <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
                      <div className="mb-2 flex flex-row items-center rounded-lg bg-brand-500 px-3 py-2 text-xs font-bold uppercase text-white transition duration-200 dark:bg-brand-400">
                        <div className="flex h-5 w-5 items-center justify-center">
                          <FaCircle />
                        </div>
                        Online
                      </div>
                    </h4>
                    <p className="text-sm font-normal text-gray-600">Status</p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <h4 className="mb-2 text-2xl font-bold text-navy-700 dark:text-white">
                      {minutesDifference + ' Minutes'}
                    </h4>
                    <p className="text-sm font-normal text-gray-600">
                      Last Login
                    </p>
                  </div>
                </div>
              </Card>
              <Card extra={'p-6 mt-6 mb-5'}>
                <Textarea
                  disabled
                  value={changelog}
                  size="sm"
                  minH="unset"
                  overflow="hidden"
                  w="100%"
                  resize="none"
                  minRows={1}
                  as={ResizeTextarea}
                  background="transparent"
                />
              </Card>
            </div>
            {/* line */}
            <div className="flex w-0 bg-gray-200 dark:bg-[#ffffff33] xl:w-px" />
            <div className="h-full w-full rounded-md">
              <div className="mt-3">
                <div className="mb-5">
                  <Card extra={'p-6'}>
                    <div className="flex items-center gap-3">
                      <div className="flex h-14 w-14 items-center justify-center rounded-full bg-lightPrimary text-4xl text-brand-500 dark:!bg-navy-700 dark:text-white">
                        <FaBoxes className="y-6 w-6" />
                      </div>
                      <div>
                        <p className="mt-1 text-2xl font-bold leading-6 text-navy-900 dark:text-white">
                          Host Statistics
                        </p>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
                <Widget
                  icon={<FaUsers className="h-7 w-7" />}
                  title={'Users'}
                  subtitle={stats.users}
                />
                <Widget
                  icon={<FaServer className="h-7 w-7" />}
                  title={'Servers'}
                  subtitle={stats.servers}
                />
                <Widget
                  icon={<FaGlobe className="h-6 w-6" />}
                  title={'Locations'}
                  subtitle={stats.locations}
                />
                <Widget
                  icon={<FaCoins className="h-7 w-7" />}
                  title={'Leaderboard Total'}
                  subtitle={stats.leaderboard_total + ' Coins'}
                />
              </div>

              <Card extra={'w-full !p-5 mb-5 mt-6'}>
                {/* Header */}
                <div className="w-full px-[8px]">
                  <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    License Information
                  </h4>
                </div>
                <InputField
                  field_name="status"
                  label="Status"
                  value={license_data.status}
                  id="status"
                  type="text"
                  extra="mb-3"
                  disabled
                />
                <InputField
                  field_name="companyname"
                  label="Registered To"
                  value={license_data.companyname}
                  id="companyname"
                  type="text"
                  extra="mb-3"
                  disabled
                />
                <InputField
                  field_name="productname"
                  label="Nexio Dash License Plan"
                  value={license_data.productname}
                  id="productname"
                  type="text"
                  extra="mb-3"
                  disabled
                />
                <InputField
                  field_name="limit_status"
                  label="Users / Limit"
                  value={
                    stats.users +
                    ' / ' +
                    extractNumber(license_data.productname)
                  }
                  id="limit_status"
                  type="text"
                  extra="mb-3"
                  disabled
                />
                <InputField
                  field_name="nextduedate"
                  label="Next Due Date"
                  value={license_data.nextduedate}
                  id="nextduedate"
                  type="text"
                  extra="mb-3"
                  disabled
                />
                <InputField
                  field_name="validdomain"
                  label="Valid Domains"
                  value={license_data.validdomain}
                  id="validdomain"
                  type="text"
                  extra="mb-3"
                  disabled
                />
                <InputField
                  field_name="validip"
                  label="Valid IP"
                  value={license_data.validip}
                  id="validip"
                  type="text"
                  extra="mb-3"
                  disabled
                />
                <InputField
                  field_name="validdirectory"
                  label="Valid Directory"
                  value={license_data.validdirectory}
                  id="validdirectory"
                  type="text"
                  extra="mb-3"
                  disabled
                />
              </Card>
            </div>
          </div>
          <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-md xl:flex-row">
            <div className="h-full w-full rounded-md">
              <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                <div className="col-span-6 h-full w-full rounded-md 3xl:col-span-4">
                  <div className="h-full w-full rounded-[14px]  "></div>
                </div>
              </div>
              <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-1"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
