import Card from 'components/card';
import InputField from 'components/fields/InputField';
import api from '../../../api.js';
import { useAdminAuth } from 'contexts/AuthContextAdmin';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import SwitchField from 'components/fields/SwitchField';

const UpdateCoupon = () => {
  useAdminAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has('id') === false) {
    Swal.fire({
      title: 'Error!',
      text: `Invalid Location! Please try again.`,
      icon: 'error',
      confirmButtonText: 'OK',
    }).then(() => {
      window.location.replace('/admin/locations');
    });
  }
  const id = queryParams.get('id');
  const [buttonloading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [location_data, setLocation] = useState({});
  useEffect(() => {
    api
      .get(`/admin/server/location/get/${id}`)
      .then((response) => {
        if (response.data.status === 'success') {
          setLocation(response.data.location);
        }
      })
      .catch((error) => {
        console.error('Error getting Location: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const name = form.get('name');
    const location_id = form.get('location_id');
    const creation_fee = form.get('creation_fee');
    let disabled;
    if (form.get('disabled') === 'on') {
      disabled = 1;
    } else {
      disabled = 0;
    }
    api
      .post('/admin/server/location/update', {
        id,
        name,
        location_id,
        creation_fee,
        disabled,
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Location has been edited, you will now be redirected to the locations dashboard.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/admin/locations';
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Error while editing the location: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Error while editing the location, please try again.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      });
  };
  return (
    <div className="mt-3 h-full w-full">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
          <Card extra={'h-full mx-auto pb-3 mt-10'}>
            <form onSubmit={handleSubmit}>
              <div className="rounded-[20px]">
                <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
                  {/* Header */}
                  <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                    Location Info
                  </h4>
                  <InputField
                    field_name="name"
                    extra="mb-3"
                    label="Location Name*"
                    value={location_data.name}
                    id="name"
                    type="text"
                    required
                  />
                  <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
                    <InputField
                      field_name="location_id"
                      label="Location ID"
                      value={location_data.location_id}
                      id="location_id"
                      type="number"
                      extra="mb-3"
                      required
                    />
                    <InputField
                      field_name="creation_fee"
                      label="Coins needed to create a server on this location"
                      value={location_data.creation_fee}
                      id="creation_fee"
                      type="number"
                      min={1}
                      extra="mb-3"
                    />
                  </div>
                  <SwitchField
                    id="disabled"
                    label="Disable Location"
                    field_name="disabled"
                    defaultChecked={location_data.disabled === 1}
                    mb="mb-3"
                    mt="mt-7"
                  />
                </div>
              </div>
              <div className="mt-4 flex flex-col justify-between px-3 pt-2 md:flex-row md:px-8">
                {buttonloading ? (
                  <button
                    className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    disabled
                  >
                    <Spinner
                      size="xl"
                      thickness="2px"
                      style={{ height: 14, width: 14 }}
                    />
                  </button>
                ) : (
                  <button className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2">
                    Update Location
                  </button>
                )}
              </div>
            </form>
          </Card>
        </div>
      )}
    </div>
  );
};

export default UpdateCoupon;
