import axios from 'axios';

const getApiBaseUrl = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}/api`;
};

const api = axios.create({
  baseURL: getApiBaseUrl(),
  withCredentials: 'include',
});

export default api;
