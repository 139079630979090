import { useAdminAuth } from 'contexts/AuthContextAdmin';
import api from '../../../api.js';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { Spinner } from '@chakra-ui/spinner';

const GeneralSettings = () => {
  useAdminAuth();
  const [buttonloading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [general_settings, setGeneralSettings] = useState({});
  const [panel_settings, setPanelSettings] = useState({});
  useEffect(() => {
    api
      .get(`/admin/settings/general`)
      .then((response) => {
        if (response.data.status === 'success') {
          setGeneralSettings(response.data.general_settings);
          setPanelSettings(response.data.panel_settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Data: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const email_address = form.get('email_address');
    const sendgrid_key = form.get('sendgrid_key');
    const default_servers = form.get('default_servers');
    const links_tos = form.get('links_tos');
    const links_support = form.get('links_support');
    const assets_logo_url = form.get('assets_logo_url');
    const assets_banner_url = form.get('assets_banner_url');
    const general_logs_webhook = form.get('general_logs_webhook');
    const payment_logs_webhook = form.get('payment_logs_webhook');
    const panel_url = form.get('panel_url');
    const panel_api_key = form.get('panel_api_key');
    const panel_egg = form.get('panel_egg');
    const general_settings = {
      session_duration: '1h',
      session_duration_ks: '30d',
      email: {
        sendgrid_key: sendgrid_key,
        email_address: email_address,
      },
      default_plan: 'default',
      default_servers: default_servers,
      links: {
        tos: links_tos,
        support: links_support,
      },
      assets: {
        logo_url: assets_logo_url,
        banner_url: assets_banner_url,
      },
      logging: {
        discord: {
          general_logs_webhook: general_logs_webhook,
          payment_logs_webhook: payment_logs_webhook,
        },
      },
    };
    const panel_settings = {
      url: panel_url,
      api_key: panel_api_key,
      egg: panel_egg,
    };
    api
      .post('/admin/settings/general', {
        general_settings,
        panel_settings,
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `General Settings Updated`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Updating General Settings was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Updating General Settings was not successful!`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-md lg:grid-cols-1">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <Card extra={'w-full !p-5'}>
          {/* Header */}
          <div className="w-full px-[8px]">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              General Settings
            </h4>
            <p className="mt-1 text-base text-gray-600">
              Here you can change main dashboard settings
            </p>
          </div>
          {/* inputs */}
          <form onSubmit={handleSubmit}>
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              Email Settings
            </h4>
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <InputField
                field_name="email_address"
                label="Authorized Sendgrid SMTP Email Address"
                value={general_settings.email.email_address}
                id="email_address"
                type="email"
                extra="mb-3"
              />
              <InputField
                field_name="sendgrid_key"
                label="Sendgrid SMTP API Key (API Password)"
                value={general_settings.email.sendgrid_key}
                id="sendgrid_key"
                type="password"
                extra="mb-3"
              />
            </div>
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              Default Settings
            </h4>
            <InputField
              field_name="default_servers"
              label="Default User Server Slots"
              value={general_settings.default_servers}
              id="default_servers"
              type="number"
              min={1}
              extra="mb-3"
            />
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              Panel Settings
            </h4>
            <div className="mb-2 mt-7 grid grid-cols-3 gap-3">
              <InputField
                field_name="panel_url"
                label="Panel Base URL (https://panel.example.com)"
                value={panel_settings.url}
                id="panel_url"
                type="url"
                extra="mb-3"
              />
              <InputField
                field_name="panel_api_key"
                label="Panel API Key with All Permissions"
                value={panel_settings.api_key}
                id="panel_api_key"
                type="password"
                extra="mb-3"
              />
              <InputField
                field_name="panel_egg"
                label="Panel Egg ID (Multi Egg Strongly Recommended)"
                value={panel_settings.egg}
                id="panel_egg"
                type="number"
                extra="mb-3"
              />
            </div>
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              Footer Links Settings
            </h4>
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <InputField
                field_name="links_tos"
                label="Terms of Service Link"
                value={general_settings.links.tos}
                id="links_tos"
                type="url"
                extra="mb-3"
              />
              <InputField
                field_name="links_support"
                label="Contact/Support Link (Discord Invite)"
                value={general_settings.links.support}
                id="links_support"
                type="url"
                extra="mb-3"
              />
            </div>
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              Assets Settings
            </h4>
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <InputField
                field_name="assets_logo_url"
                label="Logo URL (Must be Direct Link to Image)"
                value={general_settings.assets.logo_url}
                id="assets_logo_url"
                type="url"
                extra="mb-3"
              />
              <InputField
                field_name="assets_banner_url"
                label="Banner URL (965 px By 1152 px, Must be Direct Link to Image)"
                value={general_settings.assets.banner_url}
                id="assets_banner_url"
                type="url"
                extra="mb-3"
              />
            </div>
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              Logging Settings
            </h4>
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <InputField
                field_name="general_logs_webhook"
                label="Discord Web Hook URL for General Logs"
                value={general_settings.logging.discord.general_logs_webhook}
                id="general_logs_webhook"
                type="url"
                extra="mb-3"
              />
              <InputField
                field_name="payment_logs_webhook"
                label="Discord Web Hook URL for Payment Logs"
                value={general_settings.logging.discord.payment_logs_webhook}
                id="payment_logs_webhook"
                type="url"
                extra="mb-3"
              />
            </div>
            <div className="mt-12 flex w-full justify-end">
              {buttonloading ? (
                <button
                  className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled="true"
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Update General Settings
                </button>
              )}
            </div>
          </form>
        </Card>
      )}
    </div>
  );
};

export default GeneralSettings;
