import Card from 'components/card';
import InputField from 'components/fields/InputField';
import api from '../../../api.js';
import { useAdminAuth } from 'contexts/AuthContextAdmin';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import { useState } from 'react';

const CreateLocation = () => {
  useAdminAuth();
  const [buttonloading, setButtonLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const name = form.get('name');
    const location_id = form.get('location_id');
    const creation_fee = form.get('creation_fee');
    api
      .post('/admin/server/location/create', {
        name,
        location_id,
        creation_fee
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Location has been created, you will now be redirected to the location dashboard.`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/admin/locations';
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Error while creating the location: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Error while creating the location, please try again.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      });
  };
  return (
    <div className="mt-3 h-full w-full">
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        <Card extra={'h-full mx-auto pb-3 mt-10'}>
          <form onSubmit={handleSubmit}>
            <div className="rounded-[20px]">
              <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
                {/* Header */}
                <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                  Location Info
                </h4>
                <InputField
                  field_name="name"
                  extra="mb-3"
                  label="Location Name*"
                  placeholder="USA Free"
                  id="name"
                  type="text"
                  required
                />
                <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
                  <InputField
                    field_name="location_id"
                    label="Location ID"
                    placeholder="1"
                    id="location_id"
                    type="number"
                    extra="mb-3"
                    required
                  />
                  <InputField
                    field_name="creation_fee"
                    label="Coins needed to create a server on this location"
                    placeholder="100"
                    id="creation_fee"
                    type="number"
                    min={1}
                    extra="mb-3"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex flex-col justify-between px-3 pt-2 md:flex-row md:px-8">
              {buttonloading ? (
                <button
                  className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                  disabled
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2">
                  Create Location
                </button>
              )}
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default CreateLocation;
