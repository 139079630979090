import React, { useState, useEffect } from 'react';
import { useAdminAuth } from 'contexts/AuthContextAdmin';
import UsersTable from './components/UsersTable';
import api from '../../../api';
import { Spinner } from '@chakra-ui/spinner';

const AdminUsers = () => {
  useAdminAuth();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    api
      .get(`/admin/users/get`)
      .then((response) => {
        if (response.data.status === 'success') {
          setUsers(response.data.users);
        }
      })
      .catch((error) => {
        console.error('Error getting Servers: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tableColumns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'FIRSTNAME',
      accessor: 'firstname',
    },
    {
      Header: 'LASTNAME',
      accessor: 'lastname',
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
    },
    {
      Header: 'COINS',
      accessor: 'coins',
    },
    {
      Header: 'ACTIONS',
      accessor: 'actions',
    },
  ];

  return (
    <div className="h-full w-full rounded-md">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <div className="mt-3 h-full w-full rounded-[14px]  ">
            <UsersTable columnsData={tableColumns} tableData={users} />
          </div>
        </>
      )}
    </div>
  );
};

export default AdminUsers;
