import { useAdminAuth } from 'contexts/AuthContextAdmin';
import api from '../../../api.js';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Card from 'components/card';
import SwitchField from 'components/fields/SwitchField';
import InputField from 'components/fields/InputField';
import { Spinner } from '@chakra-ui/spinner';

const StoreSettings = () => {
  useAdminAuth();
  const [buttonloading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [resource_settings, setResourceSettings] = useState({});
  const [gateways_settings, setGatewaysSettings] = useState({});
  useEffect(() => {
    api
      .get(`/admin/settings/store`)
      .then((response) => {
        if (response.data.status === 'success') {
          setResourceSettings(response.data.resource_settings);
          setGatewaysSettings(response.data.gateways_settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Data: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const cpu_price = form.get('cpu_price');
    const ram_price = form.get('ram_price');
    const disk_price = form.get('disk_price');
    const databases_price = form.get('databases_price');
    const extra_ports_price = form.get('extra_ports_price');
    const backup_slots_price = form.get('backup_slots_price');
    const renewal_enabled = form.get('renewal_enabled') === 'on';
    const renewal_factor = form.get('renewal_factor');
    const purchases_enabled = form.get('purchases_enabled') === 'on';
    const coins_price = form.get('coins_price');
    const server_slots_price = form.get('server_slots_price');
    // const paypal_enabled = form.get('paypal_enabled') === 'on';
    const paypal_enabled = true;
    const paypal_client_id = form.get('paypal_client_id');
    const paypal_client_secret = form.get('paypal_client_secret');
    const resource_settings = {
      cpu: cpu_price,
      ram: ram_price,
      disk: disk_price,
      databases: databases_price,
      extra_ports: extra_ports_price,
      backup_slots: backup_slots_price,
      renewal_enabled: renewal_enabled,
      renewal_factor: renewal_factor,
    };
    const gateways_settings = {
      enabled: purchases_enabled,
      coins: coins_price,
      server_slots: server_slots_price,
      currency: 'USD',
      paypal: {
        enabled: paypal_enabled,
        client_id: paypal_client_id,
        client_secret: paypal_client_secret,
      },
    };
    api
      .post('/admin/settings/store', {
        resource_settings,
        gateways_settings,
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Store Settings Updated`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Updating Store Settings was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Updating Store Settings was not successful!`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-md lg:grid-cols-1">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <Card extra={'w-full !p-5'}>
          {/* Header */}
          <div className="w-full px-[8px]">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Store Settings
            </h4>
            <p className="mt-1 text-base text-gray-600">
              Here you can change dashboard store settings
            </p>
          </div>
          {/* inputs */}
          <form onSubmit={handleSubmit}>
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              Resource Pricing
            </h4>
            <div className="mb-2 mt-7 grid grid-cols-3 gap-3">
              <InputField
                field_name="cpu_price"
                label="Coins needed to buy 10% of CPU"
                value={resource_settings.cpu}
                id="cpu_price"
                type="number"
                min={1}
                extra="mb-3"
              />
              <InputField
                field_name="ram_price"
                label="Coins needed to buy 512 MB (0.5 GB) of RAM"
                value={resource_settings.ram}
                id="ram_price"
                type="number"
                min={1}
                extra="mb-3"
              />
              <InputField
                field_name="disk_price"
                label="Coins needed to buy 1024 MB (1 GB) of Disk"
                value={resource_settings.disk}
                id="disk_price"
                type="number"
                min={1}
                extra="mb-3"
              />
            </div>
            <div className="mb-2 mt-7 grid grid-cols-3 gap-3">
              <InputField
                field_name="databases_price"
                label="Coins needed to buy 1 Database Slot"
                value={resource_settings.databases}
                id="databases_price"
                type="number"
                min={1}
                extra="mb-3"
              />
              <InputField
                field_name="extra_ports_price"
                label="Coins needed to buy 1 Extra Port Slot (Allocations)"
                value={resource_settings.extra_ports}
                id="extra_ports_price"
                type="number"
                min={1}
                extra="mb-3"
              />
              <InputField
                field_name="backup_slots_price"
                label="Coins needed to buy 1 Server Backup Slot"
                value={resource_settings.backup_slots}
                id="backup_slots_price"
                type="number"
                min={1}
                extra="mb-3"
              />
            </div>
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <SwitchField
                id="renewal_enabled"
                label="Enable Server Renewal with Coins"
                field_name="renewal_enabled"
                defaultChecked={resource_settings.renewal_enabled}
                mb="mb-3"
                mt="mt-7"
              />
              <InputField
                field_name="renewal_factor"
                label="Renewal Multiplier Applied to Renew Costs"
                value={resource_settings.renewal_factor}
                id="renewal_factor"
                type="number"
                step="0.01"
                min={0.01}
                extra="mb-3"
              />
            </div>
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              Payment Gateways Settings
            </h4>
            <SwitchField
              id="purchases_enabled"
              label="Enable Purchase of Coins and Extra Server Slots with Real Money"
              field_name="purchases_enabled"
              defaultChecked={gateways_settings.enabled}
              mb="mb-3"
              mt="mt-7"
            />
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <InputField
                field_name="coins_price"
                label="Coins Per US Dollar"
                value={gateways_settings.coins}
                id="coins_price"
                type="number"
                min={1}
                extra="mb-3"
              />
              <InputField
                field_name="server_slots_price"
                label="US Dollar Per Extra Server Slots"
                value={gateways_settings.server_slots}
                id="server_slots_price"
                type="number"
                min={1}
                extra="mb-3"
              />
            </div>
            {/* <SwitchField
              id="paypal_enabled"
              label="Enable PayPal Payment Gateway"
              field_name="paypal_enabled"
              defaultChecked={gateways_settings.paypal.enabled}
              mb="mb-3"
              mt="mt-7"
            /> */}
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <InputField
                field_name="paypal_client_id"
                label="PayPal Rest API Client ID"
                value={gateways_settings.paypal.client_id}
                id="paypal_client_id"
                type="text"
                extra="mb-3"
              />
              <InputField
                field_name="paypal_client_secret"
                label="PayPal Rest API Client Secret"
                value={gateways_settings.paypal.client_secret}
                id="paypal_client_secret"
                type="password"
                extra="mb-3"
              />
            </div>
            <div className="mt-12 flex w-full justify-end">
              {buttonloading ? (
                <button
                  className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled="true"
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Update Store Settings
                </button>
              )}
            </div>
          </form>
        </Card>
      )}
    </div>
  );
};

export default StoreSettings;
