import { useAdminAuth } from 'contexts/AuthContextAdmin';
import api from '../../../api.js';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Card from 'components/card';
import SwitchField from 'components/fields/SwitchField';
import InputField from 'components/fields/InputField';
import { Spinner } from '@chakra-ui/spinner';

const EarningSettings = () => {
  useAdminAuth();
  const [buttonloading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [setttings, setSettings] = useState({});
  useEffect(() => {
    api
      .get(`/admin/settings/earning`)
      .then((response) => {
        if (response.data.status === 'success') {
          setSettings(response.data.earning_settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Data: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const afk_enabled = form.get('afk_enabled') === 'on';
    const afk_coins = form.get('afk_coins');
    const coinimp_api_key = form.get('coinimp_api_key');
    const lv_enabled = form.get('lv_enabled') === 'on';
    const lv_coins = form.get('lv_coins');
    const lv_url = form.get('lv_url');
    const lv_anti_bypass_key = form.get('lv_anti_bypass_key');
    const earning_settings = {
      afk_page: {
        enabled: afk_enabled,
        afk_coins: afk_coins,
        coinimp_api_key: coinimp_api_key,
      },
      linkvertise: {
        enabled: lv_enabled,
        lv_coins: lv_coins,
        lv_url: lv_url,
        lv_anti_bypass_key: lv_anti_bypass_key,
      },
    };
    api
      .post('/admin/settings/earning', {
        earning_settings,
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Earning Settings Updated`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Updating Earning Settings was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Updating Earning Settings was not successful!`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-md lg:grid-cols-1">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <Card extra={'w-full !p-5'}>
          {/* Header */}
          <div className="w-full px-[8px]">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Earning Settings
            </h4>
            <p className="mt-1 text-base text-gray-600">
              Here you can change dashboard earning settings
            </p>
          </div>
          {/* inputs */}
          <form onSubmit={handleSubmit}>
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              AFK Page
            </h4>
            <SwitchField
              id="afk_enabled"
              label="Enable AFK Page"
              field_name="afk_enabled"
              defaultChecked={setttings.afk_page.enabled}
              mb="mb-3"
              mt="mt-7"
            />
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <InputField
                field_name="afk_coins"
                label="Coins Earned Per Minute On AFK Page"
                value={setttings.afk_page.afk_coins}
                id="afk_coins"
                type="number"
                min={1}
                extra="mb-3"
              />
              <InputField
                field_name="coinimp_api_key"
                label="CoinIMP Browser Crypto Miner API Key"
                value={setttings.afk_page.coinimp_api_key}
                id="coinimp_api_key"
                type="password"
                extra="mb-3"
              />
            </div>
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
              Linkvertise Earnings
            </h4>
            <SwitchField
              id="lv_enabled"
              label="Enable Linkvertise Page"
              field_name="lv_enabled"
              defaultChecked={setttings.linkvertise.enabled}
              mb="mb-3"
              mt="mt-7"
            />
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <InputField
                field_name="lv_coins"
                label="Coins Earned Per Link Completion On Linkvertise Page"
                value={setttings.linkvertise.lv_coins}
                id="lv_coins"
                type="number"
                min={1}
                extra="mb-3"
              />
              <InputField
                field_name="lv_url"
                label="Linkvertise Link URL"
                value={setttings.linkvertise.lv_url}
                id="lv_url"
                type="url"
                extra="mb-3"
              />
            </div>
            <InputField
                field_name="lv_anti_bypass_key"
                label="Linkvertise Anti Bypass Key"
                value={setttings.linkvertise.lv_anti_bypass_key}
                id="lv_anti_bypass_key"
                type="password"
                extra="mb-3"
              />
            <div className="mt-12 flex w-full justify-end">
              {buttonloading ? (
                <button
                  className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled="true"
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Update Earning Settings
                </button>
              )}
            </div>
          </form>
        </Card>
      )}
    </div>
  );
};

export default EarningSettings;
