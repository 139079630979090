import { useAdminAuth } from 'contexts/AuthContextAdmin';
import api from '../../../api.js';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Card from 'components/card';
import SwitchField from 'components/fields/SwitchField';
import InputField from 'components/fields/InputField';
import { Spinner } from '@chakra-ui/spinner';

const AuthSettings = () => {
  useAdminAuth();
  const [buttonloading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [setttings, setSettings] = useState({});
  useEffect(() => {
    api
      .get(`/admin/settings/auth`)
      .then((response) => {
        if (response.data.status === 'success') {
          setSettings(response.data.auth_settings);
        }
      })
      .catch((error) => {
        console.error('Error getting Data: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const discord_enabled = form.get('discord_enabled') === 'on';
    const discord_client_id = form.get('discord_client_id');
    const discord_client_secret = form.get('discord_client_secret');
    const discord_server_id = form.get('discord_server_id');
    const discord_bot_token = form.get('discord_bot_token');
    const rest_api_key = form.get('rest_api_key');
    const auth_settings = {
      discord: {
        enabled: discord_enabled,
        oauth2: {
          client_id: discord_client_id,
          client_secret: discord_client_secret,
          server_id: discord_server_id,
        },
        bot: {
          token: discord_bot_token,
          api_token: rest_api_key,
        },
      },
    };
    api
      .post('/admin/settings/auth', {
        auth_settings,
      })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `Auth Settings Updated`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Updating Auth Settings was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Updating Auth Settings was not successful!`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-md lg:grid-cols-1">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <Card extra={'w-full !p-5'}>
          {/* Header */}
          <div className="w-full px-[8px]">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Auth Settings
            </h4>
            <p className="mt-1 text-base text-gray-600">
              Here you can change dashboard authentication settings
            </p>
          </div>
          {/* inputs */}
          <form onSubmit={handleSubmit}>
            <SwitchField
              id="discord_enabled"
              label="Enable Discord Authentication"
              field_name="discord_enabled"
              defaultChecked={setttings.discord.enabled}
              mb="mb-3"
              mt="mt-7"
            />
            <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
              <InputField
                field_name="discord_client_id"
                label="Discord Client ID"
                value={setttings.discord.oauth2.client_id}
                id="discord_client_id"
                type="text"
                extra="mb-3"
              />
              <InputField
                field_name="discord_client_secret"
                label="Discord Client Secret"
                value={setttings.discord.oauth2.client_secret}
                id="discord_client_secret"
                type="password"
                extra="mb-3"
              />
            </div>
            <InputField
              field_name="discord_server_id"
              label="Discord Server ID"
              value={setttings.discord.oauth2.server_id}
              id="discord_server_id"
              type="text"
              minlength="19"
              maxlength="19"
              extra="mb-3"
            />
            <InputField
              field_name="discord_bot_token"
              label="Discord Bot Token"
              value={setttings.discord.bot.token}
              id="discord_bot_token"
              type="password"
              extra="mb-3"
            />
            <InputField
              field_name="rest_api_key"
              label="Rest API Key to Allow for Rest API Endpoints"
              value={setttings.discord.bot.api_token}
              id="rest_api_key"
              type="password"
              extra="mb-3"
            />
            <div className="mt-12 flex w-full justify-end">
              {buttonloading ? (
                <button
                  className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled="true"
                >
                  <Spinner
                    size="xl"
                    thickness="2px"
                    style={{ height: 14, width: 14 }}
                  />
                </button>
              ) : (
                <button className="linear rounded-md bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Update Auth Settings
                </button>
              )}
            </div>
          </form>
        </Card>
      )}
    </div>
  );
};

export default AuthSettings;
