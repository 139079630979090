import { useState, useEffect } from 'react';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import api from '../../api.js';
import { useAuth } from 'contexts/AuthContext';
import Swal from 'sweetalert2';
import { Spinner } from '@chakra-ui/spinner';
import Widget from './components/Widget';
import {
  FaServer,
  FaCoins,
  FaBoxes,
  FaMemory,
  FaDatabase,
  FaEthernet,
} from 'react-icons/fa';
import { MdBackup } from 'react-icons/md';
import { FaMicrochip } from 'react-icons/fa6';
import { BsDeviceHddFill } from 'react-icons/bs';
import { Select } from '@chakra-ui/react';

const CreateServer = () => {
  const auth = useAuth();
  const user = auth.user;
  const [buttonloading, setButtonLoading] = useState(false);
  const resources_data = JSON.parse(user.resources_data);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    api
      .get(`/server/locations`)
      .then((response) => {
        if (response.data.status === 'success') {
          setLocations(response.data.locations);
        }
      })
      .catch((error) => {
        console.error('Error getting Servers: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const name = form.get('name');
    const location = form.get('location');
    const cpu = form.get('cpu');
    const ram = form.get('ram');
    const disk = form.get('disk');
    const dbs = form.get('databases');
    const extra_ports = form.get('ports');
    const backup_slots = form.get('backups');
    const server_data = JSON.stringify({
      name,
      location,
      resources: {
        cpu,
        ram,
        disk,
        dbs,
        extra_ports,
        backup_slots,
      },
    });
    const id = auth.user.id;
    api.post('/server/create', { id, data: server_data }).then(({ data }) => {
      setButtonLoading(false);
      if (data.status === 'success') {
        Swal.fire({
          title: 'Success!',
          text: `Server ${name} has been created, you will now be redirected to the dashboard.`,
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.href = '/dashboard';
        });
      } else if (data.status === 'error') {
        Swal.fire({
          title: 'Error!',
          text: `Error while setting up the server: ${data.message}`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: `Error while setting up the server, please try again.`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    });
  };
  return (
    <div className="mt-3 h-full w-full">
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
          <div className="mb-5 h-full w-full rounded-md">
            <div className="mt-3">
              <div className="mb-5">
                <Card extra={'p-6'}>
                  <div className="flex items-center gap-3">
                    <div className="flex h-14 w-14 items-center justify-center rounded-full bg-lightPrimary text-4xl text-brand-500 dark:!bg-navy-700 dark:text-white">
                      <FaBoxes className="y-6 w-6" />
                    </div>
                    <div>
                      <p className="mt-1 text-2xl font-bold leading-6 text-navy-900 dark:text-white">
                        Available Resources
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
              <Widget
                icon={<FaCoins className="h-7 w-7" />}
                title={'Coins'}
                subtitle={user.coins}
              />
              <Widget
                icon={<FaServer className="h-7 w-7" />}
                title={'Server Slots'}
                subtitle={resources_data.server_slots}
              />
              <Widget
                icon={<FaMicrochip className="h-6 w-6" />}
                title={'CPU'}
                subtitle={resources_data.cpu + '%'}
              />
              <Widget
                icon={<FaMemory className="h-7 w-7" />}
                title={'RAM'}
                subtitle={resources_data.ram + ' MB'}
              />
            </div>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
              <Widget
                icon={<BsDeviceHddFill className="h-7 w-7" />}
                title={'Disk'}
                subtitle={
                  Math.round((resources_data.disk / 1024) * 10) / 10 + ' GB'
                }
              />
              <Widget
                icon={<FaDatabase className="h-7 w-7" />}
                title={'Databases'}
                subtitle={resources_data.dbs}
              />
              <Widget
                icon={<FaEthernet className="h-6 w-6" />}
                title={'Extra Ports/Allocations'}
                subtitle={resources_data.extra_ports}
              />
              <Widget
                icon={<MdBackup className="h-7 w-7" />}
                title={'Backup Slots'}
                subtitle={resources_data.backup_slots}
              />
            </div>
          </div>
          <Card extra={'h-full mx-auto pb-3'}>
            <form onSubmit={handleSubmit}>
              <div className="rounded-[20px]">
                <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
                  {/* Header */}
                  <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                    Server Info
                  </h4>
                  <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
                    <InputField
                      extra="mb-3"
                      label="Server Name"
                      placeholder="Example Server"
                      id="name"
                      field_name="name"
                      type="text"
                      required
                    />
                    <div className="mb-3">
                      <label
                        htmlFor="location"
                        className={`text-sm text-navy-700 dark:text-white`}
                      >
                        Server Location
                      </label>
                      <Select
                        name="location"
                        id="location"
                        placeholder="Select Server Location"
                        style={{ background: 'rgba(0, 0, 0, 0.5)' }}
                        className="mb-3 mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      >
                        {locations
                          .filter((location) => location.disabled !== 1)
                          .map((location) => (
                            <option key={location.id} value={location.id}>
                              {location.name} ({location.creation_fee} Coins
                              Creation Fee)
                            </option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                    Server Resources
                  </h4>
                  <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-3">
                    <InputField
                      extra="mb-3"
                      label="CPU (%)"
                      placeholder="300"
                      id="cpu"
                      field_name="cpu"
                      type="number"
                      min="10"
                      max="300"
                      required
                    />
                    <InputField
                      extra="mb-3"
                      label="RAM (MB)"
                      placeholder="2048"
                      id="ram"
                      field_name="ram"
                      type="number"
                      min="128"
                      max="8192"
                      required
                    />
                    <InputField
                      extra="mb-3"
                      label="Disk (MB)"
                      placeholder="5120"
                      id="disk"
                      field_name="disk"
                      type="number"
                      min="512"
                      max="10240"
                      required
                    />
                  </div>
                  <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-3">
                    <InputField
                      extra="mb-3"
                      label="Server Database Slots"
                      placeholder="1"
                      id="databases"
                      field_name="databases"
                      type="number"
                      min="0"
                      required
                    />
                    <InputField
                      extra="mb-3"
                      label="Extra Server Ports"
                      placeholder="2"
                      id="ports"
                      field_name="ports"
                      type="number"
                      min="0"
                      required
                    />
                    <InputField
                      extra="mb-3"
                      label="Server Backup Slots"
                      placeholder="5"
                      id="backups"
                      field_name="backups"
                      type="number"
                      min="0"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 flex flex-col justify-between px-3 pt-2 md:flex-row md:px-8">
                {buttonloading ? (
                  <button
                    className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                    disabled
                  >
                    <Spinner
                      size="xl"
                      thickness="2px"
                      style={{ height: 14, width: 14 }}
                    />
                  </button>
                ) : (
                  <button className="linear cursor-pointer rounded-md bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2">
                    Create Server
                  </button>
                )}
              </div>
            </form>
          </Card>
        </div>
      )}
    </div>
  );
};

export default CreateServer;
